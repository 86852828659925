// "use strict";

var checkCorporateEmail = {
    checkEmailFunc: () => {
        var blocked = [
            "gmail",
            "yahoo",
            "outlook",
            "hotmail",
            "aol",
            "icloud",
            "protonmail",
            "mail",
            "yandex",
            "zoho",
            "gmx",
            "msn",
            "live",
            "orange",
            "laposte",
            "free",
            "web",
            "gmx",
            "t-online",
            "libero",
            "virgilio",
            "qq",
            "163",
            "126",
            "sina",
            "naver",
            "hanmail",
            "daum",
            "uol",
            "bol",
            "tutanota",
            "fastmail",
            "hushmail",
            "inbox",
            "rocketmail",
            "rediffmail"
        ];

        document.querySelector('body').addEventListener("click", (event) => {
            if( event.target.name == 'Person.Email' || event.target.type == 'email' ) {
                event.target.addEventListener("change", (event) => {
                    var email_slice =  event.target.value.split('@')[1]
                    var documentElement = document.createElement('div');
                    documentElement.id = 'corporate-email'
                    documentElement.classList.add("o--ErrorMessages--errorMessages","o--ErrorMessages--single","o--ErrorMessages--cornerShape-rounded");
                    documentElement.innerHTML= '<ul><li>Only Corporate Email allowed</li></ul>';

                    if (blocked.includes(email_slice.substr(0, email_slice.indexOf('.')))) {
                        if (document.getElementById("corporate-email")) {
                            document.getElementById("corporate-email").remove();
                        }
                        document.querySelector('.o--plan-info').append(documentElement);
                        document.querySelector('button').disabled = true;
                    } else {
                        if (document.getElementById("corporate-email")) {
                            document.getElementById("corporate-email").remove();
                        }
                        document.querySelector('button').disabled = false;
                    }
                });
            }
        });
    }
}

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = { checkCorporateEmail }
}
